import React, { useEffect, useMemo, useState } from "react";
import Link from "next/link";
import Tealium from "@4tn/webx-analytics/lib/Tealium";
import { styled } from "@mui/material";
import { colors, fonts } from "@constants/cssVariables";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import getPageCategory from "@utils/common/getPageCategory";
import { getDateLabel, getUpdateStatus } from "@utils/pageContent/dateLabel";

export interface HeadlineProps {
  title?: string;
  category?: Category;
  createdAt: string;
  updatedAt: string | null;
  sponsor?: Sponsor;
  hasVideo: boolean;
}

const HeadlineWrapper = styled("div", { shouldForwardProp: (prop) => prop !== "isSponsored" })<{
  isSponsored?: boolean;
}>(({ theme, isSponsored }) => ({
  paddingTop: theme.spacing(8),
  "& > * + *": {
    marginTop: theme.spacing(isSponsored ? 2 : 4),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(isSponsored ? 3 : 6),
    },
  },
  ...(!isSponsored && {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(6),
    },
  }),
}));

const HeadlineTitle = styled("h1")(({ theme }) => ({
  color: colors.neutral90,
  font: fonts.header1,
  wordBreak: "break-word",
  [theme.breakpoints.up("md")]: {
    flexShrink: "0",
  },
}));

const DateAndCategoryWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(2),
}));

const Category = styled("p")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: colors.primary,
  font: fonts.category,
  textTransform: "uppercase",
  [theme.breakpoints.up("md")]: {
    paddingBottom: "0px",
  },

  "&:hover": {
    color: colors.primary120,
  },
}));

const PublishDate = styled("p")(() => ({
  display: "flex",
  alignItems: "center",
  color: colors.neutral40,
  font: fonts.bodySmall,
}));

const trackClick = (categoryName: string, url: string, hasVideo: boolean, sponsored: boolean) => {
  Tealium.link({
    event_category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
    event_name: TEALIUM_EVENT_NAME.CONTENT_LISTING_CLICK,
    event_label: categoryName,
    content_type: getPageCategory(url, hasVideo, sponsored),
    content_category: categoryName,
  });
};

const Headline: React.FC<HeadlineProps> = ({ title, category, createdAt, updatedAt, hasVideo, sponsor, ...props }) => {
  const date = useMemo(() => getDateLabel(createdAt), [createdAt]);
  const [updateLabel, setUpdateLabel] = useState("");

  useEffect(() => {
    setUpdateLabel(getUpdateStatus(createdAt, updatedAt));
  }, [createdAt, updatedAt]);

  return (
    <HeadlineWrapper isSponsored={!!sponsor}>
      {title && <HeadlineTitle>{title}</HeadlineTitle>}
      <DateAndCategoryWrapper>
        {!!category && !sponsor && (
          <Link href={category.url} onClick={() => trackClick(category.title, category.url, hasVideo, false)}>
            <Category>{category.title}</Category>
          </Link>
        )}
        <PublishDate>
          {date}
          {updateLabel}
        </PublishDate>
      </DateAndCategoryWrapper>
    </HeadlineWrapper>
  );
};

export default Headline;
